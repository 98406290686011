import { ApiHook } from "../../hooks/apiHook";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Loader from "react-js-loader";
import { useTranslation } from "react-i18next";
import Tree from "react-d3-tree";
import userProfile from "../../assests/images/user-profile.png";
import Select from "react-select";
import { useState } from "react";
import { useSelector } from "react-redux";

const BoardTree = () => {
  const { t } = useTranslation();
  // const history = useHistory();
  const location = useLocation();
  const currentUrl = location.pathname;
  const navigate = useNavigate();
  const { user_id, board, boardType } = useParams();
  const {
    isLoading = true,
    isError = true,
    data = {},
  } = ApiHook.CallGetBoardTree(user_id, board);
  const [selectedBoard, setSelectedBoard] = useState({
    label: board,
    value: board,
  });

  const handleChange = (selectedOption) => {
    console.log("selectedOption", selectedOption);
    setSelectedBoard(selectedOption);
    const updatedURL = `/${boardType}/${user_id}/${selectedOption.value}`;
    navigate(updatedURL);
  };

  console.log(currentUrl, "currentUrl");
  if (isLoading || isError) {
    return (
      <div
        style={{
          // display: "flex",
          // flex: 1,
          // alignItems: "center",
          // justifyContent: "center",
          // height: "100vh",
          position: "absolute",
          bottom: "50%",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <Loader
          type="spinner-circle"
          bgColor={"#ada7a7"}
          color={"#ada7a7"}
          size={500}
        />
      </div>
    );
  } else {
    if (data.data && data.data.status) {
      const renderRectSvgNode = ({ nodeDatum, toggleNode }) => (
        <g>
          <rect
            style={{
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={toggleNode}
          />
          <image
            href={userProfile}
            x="-32.5"
            width="65"
            height="65"
            onClick={toggleNode}
          />
          <text fill="blue" strokeWidth="1" x="20">
            {nodeDatum.name}
          </text>
          <text fill="green" x="20" dy="20" strokeWidth="1">
            {nodeDatum.attributes?.name}
          </text>
        </g>
      );

      return (
        <div>
          <div
            id="treeWrapper"
            style={{ width: "100%", height: window.screen.height }}
          >
            {console.log(data.data.data.boards)}
            {!currentUrl.includes("admin-boards") && (
              <div className="mb-5">
                <div className="col-6"></div>
                <div className="col-6">
                  <Select
                    options={data.data.data.boards}
                    value={selectedBoard}
                    onChange={handleChange}
                    // isMulti
                  />
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100vh",
                margin: "auto",
                // pointerEvents: "none",
              }}
            >
              <Tree
                data={data.data.data.orgChart}
                orientation="vertical"
                renderCustomNodeElement={renderRectSvgNode}
                nodeSize={{ x: 250, y: 200 }}
                transitionDuration={0}
                // dimensions={"center"}
                pathFunc={"step"}
                translate={{ x: 500, y: 50 }}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return data.description ? (
        <div
          style={{
            // display: "flex",
            // flex: 1,
            // alignItems: "center",
            // justifyContent: "center",
            // height: "100vh",
            position: "absolute",
            bottom: "50%",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <h6>{t(data.description)}</h6>
        </div>
      ) : (
        <div
          style={{
            // display: "flex",
            // flex: 1,
            // alignItems: "center",
            // justifyContent: "center",
            // height: "100vh",
            position: "absolute",
            bottom: "50%",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <h6>Not Available..!</h6>
        </div>
      );
    }
  }
};

export default BoardTree;
