import React, { useState } from "react";
import OptionsButton from "../../components/Common/buttons/OptionsButton";
import FundTransfer from "../../components/Ewallet/FundTransfer";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CashPointTiles from "../../components/CashPoint/CashPointTiles";
import CashPointTable from "../../components/CashPoint/CashPointTable";
import CashPointFundTrasnferOverLay from "../../components/CashPoint/CashPointFundTransfer";

const CashPointLayout = () => {
  const [showTransfer, setShowTransfer] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();
  const style = {
    position: "absolute",
    inset: "0px auto auto 0px, margin: 0px",
    transform: "translate(190px, 42px)",
  };

  const handleCashPointTransfer = () => {
    setShowTransfer((prevShowTransfer) => !prevShowTransfer);
  };
  const cashPoint = ApiHook.CashPointTiles();
  const userSelectedCurrency = useSelector(
    (state) => state.user?.selectedCurrency
  );
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );
  const moduleStatus = useSelector(
    (state) => state.dashboard?.appLayout?.moduleStatus
  );

  return (
    <>
      <div className="page_head_top">{t("cashPoint")}</div>
      <div className="ewallet_top_btn_sec">
        <div className="row justify-content-between">
          <div className="col-md-4"></div>
          <div className="col-md-4 text-end">
            <OptionsButton
              title={"cash_point_fund_transfer"}
              handleOpen={handleCashPointTransfer}
              style={style}
              type={"cash_point"}
            />
          </div>
        </div>
      </div>
      <CashPointTiles
        tiles={cashPoint?.data}
        currency={userSelectedCurrency}
        conversionFactor={conversionFactor}
      />
      <CashPointTable
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        currency={userSelectedCurrency}
      />
      <CashPointFundTrasnferOverLay
        balance={cashPoint?.data?.balance}
        show={showTransfer}
        handleClose={handleCashPointTransfer}
        currency={userSelectedCurrency}
      />
    </>
  );
};

export default CashPointLayout;
