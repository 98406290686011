import { GoldCoinService } from "../../services/goldCoin";

export const goldCoinTiles = async () => {
  try {
    const response = await GoldCoinService.callTiles();
    return response;
  } catch (error) {
    return error.message;
  }
};

export const goldCoinStatement = async (page, itemsPerPage) => {
  try {
    const response = await GoldCoinService.callStatement(page, itemsPerPage);
    return response;
  } catch (error) {
    return error.message;
  }
};

export const goldCoinTransferHistory = async (
  page,
  itemsPerPage,
  selectedCategory,
  startDate,
  endDate
) => {
  try {
    const response = await GoldCoinService.callTransferHistory(
      page,
      itemsPerPage,
      selectedCategory,
      startDate,
      endDate
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const goldCoinPurchaseHistory = async (page, itemsPerPage) => {
  try {
    const response = await GoldCoinService.callPurchaseHistory(
      page,
      itemsPerPage
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const goldCoinMyEarnings = async (
  page,
  itemsPerPage,
  selectedCategory,
  startDate,
  endDate
) => {
  try {
    const response = await GoldCoinService.callMyEarnings(
      page,
      itemsPerPage,
      selectedCategory,
      startDate,
      endDate
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const goldCoinFundTransfer = async (data) => {
  try {
    const response = await GoldCoinService.callFundTransfer(data);
    return response;
  } catch (error) {
    return error.message;
  }
};

export const GoldCoinBalance = async () => {
  try {
    const response = await GoldCoinService.callEwalletBalance();
    return response;
  } catch (error) {
    console.log(error.message);
  }
};
