import API from "../../api/api";

const callApi = async (endpoint) => {
  try {
    const response = await API.get(endpoint);
    if (response.status === 200) {
      return response?.data?.data;
    } else {
      return response?.data?.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const GoldCoinService = {
  callTiles: async () => {
    return callApi("gold-coin-tiles");
  },
  callStatement: async (page, itemsPerPage) => {
    return callApi(`gold-coin-statement?page=${page}&perPage=${itemsPerPage}`);
  },
  callTransferHistory: async (
    page,
    itemsPerPage,
    selectedCategory,
    startDate,
    endDate
  ) => {
    return callApi(
      `/gold-coin-transfer-history?type=${selectedCategory}&startDate=${startDate}&endDate=${endDate}&page=${page}&perPage=${itemsPerPage}`
    );
  },
  callPurchaseHistory: async (page, itemsPerPage) => {
    return callApi(
      `gold-coin-purchase-wallet?page=${page}&perPage=${itemsPerPage}`
    );
  },
  callMyEarnings: async (
    page,
    itemsPerPage,
    selectedCategory,
    startDate,
    endDate
  ) => {
    return callApi(
      `gold-coin-my-earnings?page=${page}&perPage=${itemsPerPage}&startDate=${startDate}&endDate=${endDate}&direction=desc&type=${selectedCategory}`
    );
  },
  callEwalletBalance: async () => {
    return callApi("get-gold-coin-balance");
  },
  callFundTransfer: async (data) => {
    return API.post("gold-coin-fund-transfer", JSON.stringify(data))
      .then((response) => response)
      .catch((error) => Promise.reject(error));
  },
};
