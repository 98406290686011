import React from "react";
import { ApiHook } from "../../hooks/apiHook";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import StepDetails from "../../components/purchaseMembership/StepDetails";

const PurchaseMemberShip = () => {
  const { t } = useTranslation();
  const items = ApiHook.CallGetStepDetails();
  
  return (
    <>
      <div className="page_head_top">{t("purchaseMembership")}</div>
      <div className="ewallet_top_btn_sec">
        <div className="row justify-content-between">
          <div className="col-md-4 text-end">
            <div className="dropdown btn-group top_right_pop_btn_position">
              <Link to={"/purchase-details  "} className="top_righ_pop_btn">
                {t("membership_purchase_details")}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div style={{ margin: 2 }}>
        <h6>{t("noteOnPurchase")}</h6>
      </div>
      <StepDetails products={items?.data} />
    </>
  );
};

export default PurchaseMemberShip;
