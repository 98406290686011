import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import Loader from "react-js-loader";

const StepDetails = ({ products }) => {
  const [disable, setDisable] = useState(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [cartId, setCartId] = useState("");
  const userSelectedCurrency = useSelector(
    (state) => state.user?.selectedCurrency
  );
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );

  const purchaseMemberShip = ApiHook.CallPurchaseMemberShip();
  const handleAddToCart = (data) => {
    console.log(data);
    setDisable(true);
    // setCartId(id);
    purchaseMemberShip.mutate(data, {
      onSuccess: (res) => {
        setDisable(false);
        console.log(res, "response on membership purchase");
        if (res?.status) {
          queryClient.invalidateQueries({ queryKey: ["step-items"] });
          toast.success(t(res?.data));
        } else {
          toast.error(
            t(
              res?.data?.description ? res?.data?.description : res?.description
            )
          );
        }
      },
    });
  };
  return (
    <div className="shoping_page_section">
      <div className="row">
        {products?.length <= 0 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <h6>{t("empty")}</h6>
          </div>
        )}
        {products?.map((item, index) => (
          <div className="col-xl-3 col-lg-4 col-md-4" key={index}>
            <div className="product_box">
              <div
                className="product_box_image"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={"/images/step.png"}
                  alt="steps"
                  style={{ width: "50%", height: "50%" }}
                />
              </div>
              <div className="product_box_content">
                <div className="product_box_head">{item.stageName}</div>
                <div className="product_box_category">{item.category}</div>
                <div className="product_box_amnt">{`${
                  userSelectedCurrency.symbolLeft
                } ${CurrencyConverter(item.amount, conversionFactor)}`}</div>
              </div>
              <div className="product_box_btn_sec">
                <button
                  id={`btn-${index}`}
                  className="product_box_btn1"
                  onClick={() => handleAddToCart(item)}
                  disabled={disable}
                >
                  {purchaseMemberShip.isLoading && cartId === item.id && (
                    <div style={{ padding: "10px" }}>
                      <Loader type="bubble-top" bgColor={"white "} size={30} />
                    </div>
                  )}
                  <i className="fa fa-cart-shopping"></i> {t("purchase")}
                </button>
                {/* <NavLink
                  to={`/item-details/${item.id}`}
                  className="product_box_btn2"
                >
                  <i className="fa fa-eye"></i> {t("more_details")}
                </NavLink> */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StepDetails;
