import API from "../../api/api";

const callApi = async (endpoint) => {
  try {
    const response = await API.get(endpoint);
    if (response.status === 200) {
      return response?.data?.data;
    } else {
      return response?.data?.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const TronWalletService = {
  addFundToTronWallet: async (data) => {
    return API.post("add-to-tron-wallet", JSON.stringify(data))
      .then((response) => response)
      .catch((error) => Promise.reject(error));
  },
  getStatement: async (page, itemsPerPage) => {
    return callApi(
      `tron-wallet-statement?page=${page}&perPage=${itemsPerPage}`
    );
  },
  getTiles: async () => {
    return callApi("tron-tile");
  },
};
