import React, { useState } from "react";
import TableMain from "../Common/table/TableMain";
import EwalletTableFilter from "../Common/table/EwalletTableFilter";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../hooks/apiHook";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const BoardDetails = (props) => {
  const { t } = useTranslation();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [board, setBoard] = useState(1);
  const boardSelect = (data) => {
    console.log(data, "board selection");
    setBoard(data[0]);
  };
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
  // const [selectedCategory, setSelectedCategory] = useState([]);

  //------------------------------------------- API -------------------------------------------
  const data = ApiHook.CallBoardReport(1, itemsPerPage, board);
  console.log(data?.data?.data, "data");
  const headers = [t("username"), t("fullname"), t("date")];

  return (
    <div className="ewallet_table_section">
      <div className="table-responsive min-hieght-table">
        <EwalletTableFilter
          type={"board-report"}
          //   setApiTab={setApiTab}
          selectedCategory={board}
          setSelectedCategory={boardSelect}
          setDateRange={setDateRange}
          dateRange={dateRange}
          date={false}
          category={data?.data?.data?.boardDetails}
          search={false}
        />
        <TableMain
          headers={headers}
          data={data?.data?.data?.result}
          startPage={1}
          currentPage={data?.data?.data?.currentPage}
          totalPages={data?.data?.data?.totalPages}
          // setCurrentPage={props.setCurrentPage}
          type={"board-report"}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          activeTab={"details"}
        />
      </div>
    </div>
  );
};

export default BoardDetails;
