import React from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../utils/formateDate";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApiHook } from "../../../hooks/apiHook";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import CurrencyConverter from "../../../Currency/CurrencyConverter";
import { TableSkeleton } from "./TableSkeleton";

const TableContent = ({
  headers,
  data,
  type,
  handleEditClick,
  tableRef,
  currency = "$",
}) => {
  console.log(data, type, "gold coin");
  const userSelectedCurrency = useSelector(
    (state) => state.user?.selectedCurrency
  );
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );
  const userImage = useSelector(
    (state) => state.dashboard?.appLayout?.user?.image
  );
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const epinRefundMutation = ApiHook.CallEpinRefund();
  const handleRefund = async (row) => {
    const epinRefundPayload = {
      epin: row?.numbers,
    };
    epinRefundMutation.mutateAsync(epinRefundPayload, {
      onSuccess: (res) => {
        if (res?.status === 200) {
          toast.success(res?.data?.data);
          queryClient.invalidateQueries({ queryKey: ["epin-tiles"] });
        } else {
          toast.error(res?.data?.data?.description);
        }
      },
    });
  };

  return (
    <div className="table-container" ref={tableRef}>
      <table className="striped" style={{ width: "100%" }}>
        <thead>
          <tr>
            {headers?.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        {type === "ewallet" && !data ? (
          <tbody>
            <TableSkeleton rowCount={6} cellCount={6} />
          </tbody>
        ) : type === "ewallet" && data?.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan="6">
                <div className="nodata-table-view">
                  <div className="nodata-table-view-box">
                    <div className="nodata-table-view-box-img">
                      <img src="/images/no-data-image1.jpg" alt="" />
                    </div>
                    <div className="nodata-table-view-box-txt">
                      {t("sorry_no_data_found")}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          type === "ewallet" && (
            <tbody>
              {data?.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.ewalletType === "commission" &&
                    row.amountType !== "gold_points" && (
                      <td>
                        <div className="profile_table">
                          <img src="/images/icons-money-bag.png" alt="" />
                        </div>
                        {`${t(row.amountType)}${
                          row.fromUser ? ` ${t("from")} ${row.fromUser}` : ""
                        }`}
                      </td>
                    )}
                  {row.ewalletType === "commission" &&
                    row.amountType === "gold_points" && (
                      <td>
                        <div className="profile_table">
                          <img src="/images/icons-money-bag.png" alt="" />
                        </div>
                        {`${t(row.amountType)}`}
                      </td>
                    )}
                  {row.ewalletType === "fund_transfer" && (
                    <td>
                      <div className="profile_table">
                        <img src="/images/icons-transfer.png" alt="" />
                      </div>
                      {`${t(row.amountType)} ${
                        row.type === "credit"
                          ? row.fromUser ?? ""
                          : row.toUser ?? ""
                      } ${
                        row.transactionFee !== 0
                          ? ` ( ${t("transactionFee")} : ${currency} ${
                              row.transactionFee
                            } )`
                          : ""
                      }`}
                    </td>
                  )}
                  {row.ewalletType === "pin_purchase" && (
                    <td>
                      <div className="profile_table">
                        <img src="/images/icons-request-money.png" alt="" />
                      </div>
                      {`${t(row.amountType)}`}
                    </td>
                  )}
                  {row.ewalletType === "ewallet_payment" && (
                    <td>
                      <div className="profile_table">
                        <img src="/images/icons-credit-card.png" alt="" />
                      </div>
                      {`${t(row.amountType)} ${t("using_ewallet")}`}
                    </td>
                  )}
                  {row.ewalletType === "cash_point_payment" && (
                    <td>
                      <div className="profile_table">
                        <img src="/images/icons-credit-card.png" alt="" />
                      </div>
                      {`${t(row.amountType)} ${t("cash_point_payment")}`}
                    </td>
                  )}
                  {row.ewalletType === "payout" && (
                    <td>
                      <div className="profile_table">
                        <img src="/images/icons-withdrawal.png" alt="" />
                      </div>
                      {`${t(row.amountType)}`}
                    </td>
                  )}
                  {row.ewalletType === "cashpoint_wallet_payment" && (
                    <td>
                      <div className="profile_table">
                        <img src="/images/icons-credit-card.png" alt="" />
                      </div>
                      {`${t(row.amountType)} ${t("cashpoint_wallet_payment")}`}
                    </td>
                  )}
                  {row.ewalletType === "goldpoint_wallet_payment" && (
                    <td>
                      <div className="profile_table">
                        <img src="/images/icons-credit-card.png" alt="" />
                      </div>
                      {`${t(row.amountType)} ${t("goldpoint_wallet_payment")}`}
                    </td>
                  )}
                  {row.totalAmount == 0 ||
                    (row.totalAmount && (
                      <td>
                        <span className="up_ewallet">
                          {`${currency} ${row.totalAmount} `}
                          <i className="fa fa-arrow-up"></i>
                        </span>
                      </td>
                    ))}
                  {/* {row.tds == 0 ||
                    (row.tds && <td>{`${currency} ${row.tds}`}</td>)} */}
                  {row.tds !== null && row.tds !== undefined ? (
                    <td>{`${currency} ${row.tds}`}</td>
                  ) : null}
                  {row.serviceCharge !== null &&
                  row.serviceCharge !== undefined ? (
                    <td>{`${currency} ${row.serviceCharge}`}</td>
                  ) : null}

                  {/* {row.serviceCharge == 0 ||
                    (row.serviceCharge && (
                      <td>{`${currency} ${row.serviceCharge}`}</td>
                    ))} */}
                  {row.amountPayable !== null &&
                  row.amountPayable !== undefined ? (
                    <td>
                      <span className="up_ewallet">
                        {`${currency} ${CurrencyConverter(
                          row.amountPayable,
                          conversionFactor
                        )} `}
                        <i className="fa fa-arrow-up"></i>
                      </span>
                    </td>
                  ) : null}
                  {/* {row.amountPayable == 0 ||
                    (row.amountPayable && (
                      <td>
                        <span className="up_ewallet">
                          {`${currency} ${CurrencyConverter(
                            row.amountPayable,
                            conversionFactor
                          )} `}
                          <i className="fa fa-arrow-up"></i>
                        </span>
                      </td>
                    ))} */}
                  {row.amount !== null && row.amount !== undefined ? (
                    <td>
                      {row.type === "credit" ? (
                        <span className="up_ewallet">
                          {`${currency} ${CurrencyConverter(
                            row.amount,
                            conversionFactor
                          )} `}
                          <i className="fa fa-arrow-up"></i>
                        </span>
                      ) : (
                        <span className="down_ewallet">
                          {`${currency} ${CurrencyConverter(
                            row.amount,
                            conversionFactor
                          )} `}
                          <i className="fa fa-arrow-down"></i>
                        </span>
                      )}
                    </td>
                  ) : null}
                  {/* {row.amount == 0 ||
                    (row.amount && (
                      <td>
                        {row.type === "credit" ? (
                          <span className="up_ewallet">
                            {`${currency} ${CurrencyConverter(
                              row.amount,
                              conversionFactor
                            )} `}
                            <i className="fa fa-arrow-up"></i>
                          </span>
                        ) : (
                          <span className="down_ewallet">
                            {`${currency} ${CurrencyConverter(
                              row.amount,
                              conversionFactor
                            )} `}
                            <i className="fa fa-arrow-down"></i>
                          </span>
                        )}
                      </td>
                    ))} */}
                  <td>{formatDate(row.dateAdded)}</td>
                  {row.balance !== null && row.balance !== undefined ? (
                    <td>
                      <span className="balance_bx">{`${currency} ${CurrencyConverter(
                        row.balance,
                        conversionFactor
                      )}`}</span>
                    </td>
                  ) : null}
                  {/* {row.balance == 0 ||
                    (row.balance && (
                      <td>
                        <span className="balance_bx">{`${currency} ${CurrencyConverter(
                          row.balance,
                          conversionFactor
                        )}`}</span>
                      </td>
                    ))} */}
                </tr>
              ))}
            </tbody>
          )
        )}
        {type === "payout" && !data ? (
          <tbody>
            <TableSkeleton rowCount={6} cellCount={6} />
          </tbody>
        ) : type === "payout" && data?.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan="6">
                <div className="nodata-table-view">
                  <div className="nodata-table-view-box">
                    <div className="nodata-table-view-box-img">
                      <img src="/images/no-data-image1.jpg" alt="" />
                    </div>
                    <div className="nodata-table-view-box-txt">
                      {t("sorry_no_data_found")}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          type === "payout" && (
            <tbody>
              {data?.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.updatedAt && <td>{formatDate(row.updatedAt)}</td>}
                  {row.amount && (
                    <td>
                      <span className="up_ewallet">
                        {`${
                          userSelectedCurrency?.symbolLeft
                        } ${CurrencyConverter(row.amount, conversionFactor)} `}
                        <i className="fa fa-arrow-up"></i>
                      </span>
                    </td>
                  )}
                  {row.paymentMethod && (
                    <td>
                      <span className="balance_bx">
                        {t(`${row.paymentMethod}`)}
                      </span>
                    </td>
                  )}
                  {row.status && (
                    <td>
                      <span className="balance_bx">{t(`${row.status}`)}</span>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          )
        )}
        {type === "epin" && !data ? (
          <tbody>
            <TableSkeleton rowCount={6} cellCount={6} />
          </tbody>
        ) : type === "epin" && data?.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan="6">
                <div className="nodata-table-view">
                  <div className="nodata-table-view-box">
                    <div className="nodata-table-view-box-img">
                      <img src="/images/no-data-image1.jpg" alt="" />
                    </div>
                    <div className="nodata-table-view-box-txt">
                      {t("sorry_no_data_found")}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          type === "epin" && (
            <tbody>
              {data?.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.name && <td>{row.name}</td>}
                  {row.epin && <td>{row.epin}</td>}
                  {row.numbers && <td>{row.numbers}</td>}
                  {row.amount && (
                    <td>
                      <span className="balance_bx">{`${
                        userSelectedCurrency?.symbolLeft
                      } ${CurrencyConverter(
                        row.amount,
                        conversionFactor
                      )} `}</span>
                    </td>
                  )}
                  {row.balanceAmount && (
                    <td>
                      <span className="up_ewallet">
                        {`${
                          userSelectedCurrency?.symbolLeft
                        } ${CurrencyConverter(
                          row.balanceAmount,
                          conversionFactor
                        )} `}
                        <i className="fa fa-arrow-up"></i>
                      </span>
                    </td>
                  )}
                  {row.status && <td>{row.status}</td>}
                  {row.requestedDate && (
                    <td>{formatDate(row.requestedDate)}</td>
                  )}
                  {row.transferredDate && (
                    <td>{formatDate(row.transferredDate)}</td>
                  )}
                  {row.expiryDate && <td>{formatDate(row.expiryDate)}</td>}
                  {row.requestedPinCount && <td>{row.requestedPinCount}</td>}
                  {row.pinAmount && (
                    <td>
                      <span className="balance_bx">{`${userSelectedCurrency?.symbolLeft} ${row.pinAmount} `}</span>
                    </td>
                  )}
                  {row.purchaseStatus === 1 && (
                    <td>
                      <Link
                        className="btn_tab_2"
                        onClick={() => handleRefund(row)}
                      >
                        {t("refund")}
                      </Link>
                    </td>
                  )}
                  {row.purchaseStatus === 0 && <td></td>}
                  {row.action && <td>{row.action}</td>}
                </tr>
              ))}
            </tbody>
          )
        )}
        {type === "downline" && !data ? (
          <tbody>
            <TableSkeleton rowCount={6} cellCount={6} />
          </tbody>
        ) : type === "downline" && data?.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan="6">
                <div className="nodata-table-view">
                  <div className="nodata-table-view-box">
                    <div className="nodata-table-view-box-img">
                      <img src="/images/no-data-image1.jpg" alt="" />
                    </div>
                    <div className="nodata-table-view-box-txt">
                      {t("sorry_no_data_found")}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          type === "downline" && (
            <tbody>
              {data?.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.fullName && (
                    <td>
                      <div className="profile_table">
                        <img
                          src={row.image ?? "/images/user-profile.png"}
                          alt=""
                        />
                      </div>
                      {row.fullName}
                      <br />
                      {row.username}
                    </td>
                  )}
                  {row.placement && <td>{row.placement}</td>}
                  {row.sponsor && <td>{row.sponsor}</td>}
                  {row.childLevel && <td>{row.childLevel}</td>}
                </tr>
              ))}
            </tbody>
          )
        )}
        {type === "referral" && !data ? (
          <tbody>
            <TableSkeleton rowCount={6} cellCount={6} />
          </tbody>
        ) : type === "referral" && data?.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan="6">
                <div className="nodata-table-view">
                  <div className="nodata-table-view-box">
                    <div className="nodata-table-view-box-img">
                      <img src="/images/no-data-image1.jpg" alt="" />
                    </div>
                    <div className="nodata-table-view-box-txt">
                      {t("sorry_no_data_found")}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          type === "referral" && (
            <tbody>
              {data?.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.fullName && (
                    <td>
                      <div className="profile_table">
                        <img
                          src={row.image ?? "/images/user-profile.png"}
                          alt=""
                        />
                      </div>
                      {row.fullName}
                      <br />
                      {row.username}
                    </td>
                  )}
                  {row.placement && <td>{row.placement}</td>}
                  {row.sponsor && <td>{row.sponsor}</td>}
                  {row.childLevel && <td>{row.childLevel}</td>}
                </tr>
              ))}
            </tbody>
          )
        )}
        {type === "leads" && !data ? (
          <tbody>
            <TableSkeleton rowCount={6} cellCount={6} />
          </tbody>
        ) : type === "leads" && data?.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan="12">
                <div className="nodata-table-view">
                  <div className="nodata-table-view-box">
                    <div className="nodata-table-view-box-img">
                      <img src="/images/no-data-image1.jpg" alt="" />
                    </div>
                    <div className="nodata-table-view-box-txt">
                      {t("sorry_no_data_found")}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          type === "leads" && (
            <tbody>
              {data?.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.id && <td>{rowIndex + 1}</td>}
                  {row.firstName ? <td>{row.firstName}</td> : <td></td>}
                  {row.lastName ? <td>{row.lastName}</td> : <td></td>}
                  {row.leadStatus === 0 && <td>{t("rejected")}</td>}
                  {row.leadStatus === 1 && <td>{t("ongoing")}</td>}
                  {row.leadStatus === 2 && <td>{t("accepted")}</td>}
                  {row.emailId ? <td>{row.emailId}</td> : <td></td>}
                  {row.mobileNo ? <td>{row.mobileNo}</td> : <td></td>}
                  {row.skypeId ? <td>{row.skypeId}</td> : <td></td>}
                  <td>
                    {" "}
                    <button
                      type="button"
                      className="btn btn-labeled btn-primary"
                      onClick={() => handleEditClick(row.id)}
                    >
                      {t("edit")}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          )
        )}
        {type === "repurchase-report" && !data ? (
          <tbody>
            <TableSkeleton rowCount={6} cellCount={6} />
          </tbody>
        ) : type === "repurchase-report" && data?.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan="12">
                <div className="nodata-table-view">
                  <div className="nodata-table-view-box">
                    <div className="nodata-table-view-box-img">
                      <img src="/images/no-data-image1.jpg" alt="" />
                    </div>
                    <div className="nodata-table-view-box-txt">
                      {t("sorry_no_data_found")}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          type === "repurchase-report" && (
            <tbody>
              {data?.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.id && <td>{row.id}</td>}
                  {row.invoiceNo &&
                    (row.status === "1" ? (
                      <td>
                        <Link to={`/repurchase-invoice/${row.id}`}>
                          {row.invoiceNo}
                        </Link>
                      </td>
                    ) : (
                      <td>{row.invoiceNo}</td>
                    ))}
                  {row.totalAmount ? (
                    <td>{`${
                      userSelectedCurrency?.symbolLeft
                    } ${CurrencyConverter(
                      row.totalAmount,
                      conversionFactor
                    )}`}</td>
                  ) : (
                    <td></td>
                  )}
                  {row.paymentMethod ? <td>{row.paymentMethod}</td> : <td></td>}
                  {row.orderDate ? (
                    <td>{formatDate(row.orderDate)}</td>
                  ) : (
                    <td></td>
                  )}
                  {row.status && row.status === "1" ? (
                    <td>{t("approved")}</td>
                  ) : (
                    <td>{t("pending")}</td>
                  )}
                </tr>
              ))}
            </tbody>
          )
        )}
        {type === "ticket" && !data ? (
          <tbody>
            <TableSkeleton rowCount={6} cellCount={10} />
          </tbody>
        ) : type === "ticket" && data?.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan="12">
                <div className="nodata-table-view">
                  <div className="nodata-table-view-box">
                    <div className="nodata-table-view-box-img">
                      <img src="/images/no-data-image1.jpg" alt="" />
                    </div>
                    <div className="nodata-table-view-box-txt">
                      {t("sorry_no_data_found")}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          type === "ticket" && (
            <tbody>
              {data?.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td>{rowIndex + 1}</td>
                  {row.trackId && (
                    <td>
                      <Link to={`/ticket-details/${row.trackId}`}>
                        {row.trackId}
                      </Link>
                    </td>
                  )}
                  {row.subject && <td>{row.subject}</td>}
                  {row.assignee ? <td>{row.assignee}</td> : <td></td>}
                  {row.status && <td>{row.status}</td>}
                  {row.category && <td>{row.category}</td>}
                  {row.priority ? <td>{row.priority}</td> : <td></td>}
                  {row.createdAt && <td>{formatDate(row.createdAt)}</td>}
                  {row.lastUpdated && <td>{formatDate(row.lastUpdated)}</td>}
                  <td>
                    <Link to={`/ticket-timeline/${row.trackId}`}>
                      <i className="fa-solid fa-expand"></i>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          )
        )}
        {type === "tronStatement" && !data ? (
          <tbody>
            <TableSkeleton rowCount={4} cellCount={6} />
          </tbody>
        ) : type === "tronStatement" && data?.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan="6">
                <div className="nodata-table-view">
                  <div className="nodata-table-view-box">
                    <div className="nodata-table-view-box-img">
                      <img src="/images/no-data-image1.jpg" alt="" />
                    </div>
                    <div className="nodata-table-view-box-txt">
                      {t("sorry_no_data_found")}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          type === "tronStatement" && (
            <tbody>
              {data?.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.id && (
                    <td>
                      <span className="balance_bx">{row.id}</span>
                    </td>
                  )}
                  {row.amount && (
                    <td>
                      <span className="up_ewallet">
                        {`${userSelectedCurrency?.symbolLeft} ${row.amount} `}
                      </span>
                    </td>
                  )}
                  <td>{formatDate(row.date)}</td>
                  {row.status && (
                    <td>
                      <span className="balance_bx">{row.status}</span>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          )
        )}
        {type === "board-report" && !data ? (
          <tbody>
            <TableSkeleton rowCount={6} cellCount={3} />
          </tbody>
        ) : type === "board-report" && data?.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan="12">
                <div className="nodata-table-view">
                  <div className="nodata-table-view-box">
                    <div className="nodata-table-view-box-img">
                      <img src="/images/no-data-image1.jpg" alt="" />
                    </div>
                    <div className="nodata-table-view-box-txt">
                      {t("sorry_no_data_found")}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          type === "board-report" && (
            <tbody>
              {data?.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {/* {row.id && <td>{row.id}</td>} */}
                  <td>{row?.username}</td>

                  {row.name ? <td>{row.name}</td> : <td></td>}
                  {row.date ? <td>{formatDate(row.date)}</td> : <td></td>}
                </tr>
              ))}
            </tbody>
          )
        )}
      </table>
    </div>
  );
};

export default TableContent;
