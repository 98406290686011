import React, { useState } from "react";
import OptionsButton from "../../components/Common/buttons/OptionsButton";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import GoldCoinTiles from "../../components/GoldCoin/GoldCoinTiles";
import GoldCoinTable from "../../components/GoldCoin/GoldCoinTable";
import GoldCoinFundTrasnferOverLay from "../../components/GoldCoin/GoldCoinFundTransferOverlay";

const GoldCoinLayout = () => {
  const [showTransfer, setShowTransfer] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();
  const style = {
    position: "absolute",
    inset: "0px auto auto 0px, margin: 0px",
    transform: "translate(190px, 42px)",
  };

  const handleGoldCoinTrasnfer = () => {
    setShowTransfer((prevShowTransfer) => !prevShowTransfer);
  };
  const goldCoin = ApiHook.CallGoldCoinTiles();
  const userSelectedCurrency = useSelector(
    (state) => state.user?.selectedCurrency
  );
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );

  return (
    <>
      <div className="page_head_top">{t("gold_coin")}</div>
      <div className="ewallet_top_btn_sec">
        <div className="row justify-content-between">
          <div className="col-md-4"></div>
          <div className="col-md-4 text-end">
            <OptionsButton
              title={"gold_point_fund_transfer"}
              handleOpen={handleGoldCoinTrasnfer}
              style={style}
              type={"gold_coin"}
            />
          </div>
        </div>
      </div>
      <GoldCoinTiles
        tiles={goldCoin?.data}
        currency={userSelectedCurrency}
        conversionFactor={conversionFactor}
      />
      <GoldCoinTable
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        currency={userSelectedCurrency}
      />
      <GoldCoinFundTrasnferOverLay
        balance={goldCoin?.data?.balance}
        show={showTransfer}
        handleClose={handleGoldCoinTrasnfer}
        currency={userSelectedCurrency}
      />
    </>
  );
};

export default GoldCoinLayout;
