import React, { useEffect, useMemo, useState } from "react";
import { TronLinkAdapter } from "@tronweb3/tronwallet-adapters";
import {
  useWallet,
  WalletProvider,
} from "@tronweb3/tronwallet-adapter-react-hooks";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

function Tron(props) {
  const [disable, setDisable] = useState(false);
  const { onError, ...buttonProps } = props;
  const receiver = props?.address;
  const { select } = useWallet();

  useEffect(() => {
    // setTimeout(() => {
    //   const tronWeb = window?.tronWeb;
    //   if (tronWeb && !tronWeb.ready) {
    //     console.error("TronWeb not available");
    //     // onError?.("TronWeb not available, install TronLink");
    //   } else {
    //     if (!tronWeb) return;
    //     const provider = tronWeb.currentProvider();
    //     if (
    //       !["https://api.trongrid.io", "https://api.tronstack.io"].includes(
    //         provider.fullNode.host
    //       ) &&
    //       props?.dev == false
    //     ) {
    //       setDisable(true);
    //     }
    //   }
    // }, 500);

    select("TronLink");

    window.addEventListener("message", (res) => {
      if (res.data.message && res.data.message.action == "setAccount") {
        if (window.tronWeb) {
          if (res.data.message.data.address != this.defaultAccount) {
            window.location.reload();
          }
        } else {
          window.location.reload();
        }
      }
      if (res.data.message && res.data.message.action == "setNode") {
        window.location.reload();
      }
    });

    if (!receiver) {
      onError?.("To address is null. Please Contact Administrator");
    }
  }, []);

  const adapters = useMemo(function () {
    const tronLink1 = new TronLinkAdapter();
    return [tronLink1];
  }, []);
  function onConnect() {
    console.log("onConnect");
    window.location.reload();
  }
  async function onAccountsChanged() {
    console.log("onAccountsChanged");
  }
  async function onAdapterChanged(adapter) {
    console.log("onAdapterChanged", adapter);
  }

  return (
    <ErrorBoundary key={"tronPay"}>
      <WalletProvider
        onError={(e) => onError?.(e.message)}
        onConnect={onConnect}
        onAccountsChanged={onAccountsChanged}
        onAdapterChanged={onAdapterChanged}
        autoConnect={false}
        adapters={adapters}
        disableAutoConnectOnLoad={true}
      >
        <PayButton
          {...buttonProps}
          receiver={receiver}
          onError={onError}
          disabled={disable}
          dev={props?.dev}
        />
      </WalletProvider>
    </ErrorBoundary>
  );
}

// <Pay
//     amount={25.6}
//     text="Send USDT"
//     tokens={["USDT"]}
//     onInitiated={(payment) => {
//       console.log("onInitiated", payment);
//     }}
//     onSuccess={async (payment) => {
//       console.log("Await for blockchain confirmation...");
//       console.log("success", payment);
//     }}
//     onError={(error) => {
//       console.log("Error", JSON.stringify(error));
//     }}
//     style={{ backgroundColor: "red" }}
//     spinningStyle={{ borderColor: "white", spinnerColor: "red" }}
//  />

const PayButton = ({
  receiver,
  amount = 1,
  text = "SEND TRX",
  onInitiated,
  onSuccess,
  style = {},
  disconnect = false,
  onError,
  disabled,
  className = "",
  dev = false,
  // tokens,
}) => {
  const [disable, setDisable] = useState(disabled);

  const {
    wallets,
    address,
    select,
    connected,
    wallet,
    connect,
    disconnect: WalletDisconnect,
    signMessage,
    signTransaction,
  } = useWallet();
  async function onSignTransaction() {
    const tronWeb = window?.tronWeb;
    if (tronWeb && !tronWeb.ready) {
      console.error("TronWeb not available");
      onError?.("Unlock TronLink or Install TronLink");
      window.alert("Unlock TronLink or Install TronLink");
    } else {
      if (!tronWeb) return;
      const provider = tronWeb.currentProvider();
      if (
        !["https://api.trongrid.io", "https://api.tronstack.io"].includes(
          provider.fullNode.host
        ) &&
        dev == false
      ) {
        alert("Switch to Mainnet");
        onError?.("Switch to Mainnet");
        setDisable(true);
        return;
      }
    }

    try {
      const myBalance_sun = await window.tronWeb.trx.getBalance(address);
      const myBalance = window.tronWeb.fromSun(parseInt(myBalance_sun));
      if (parseFloat(myBalance) < parseFloat(amount)) {
        onError?.("You don't have enough balance (" + myBalance + ")");
        return;
      }

      const transaction = await window.tronWeb.transactionBuilder.sendTrx(
        receiver,
        window.tronWeb.toSun(parseInt(amount)),
        address
      );
      const signedTransaction = await signTransaction(transaction);
      // const signedTransaction = await tronWeb.trx.sign(transaction);
      const res = await window.tronWeb.trx.sendRawTransaction(
        signedTransaction
      );
      console.log({ res });
      console.log(
        `https://nile.tronscan.org/#/address/${res.transaction.txID}`
      );
      console.log("CHECKS....", res.transaction.txID);
      onInitiated?.(res.transaction.txID);
      onSuccess(res.transaction.txID);
      // window.tronWeb.trx.getTransaction(res.transaction.txID).then((result) => {
      //   console.log(result);
      // });
    } catch (error) {
      console.log(error);
      onError(error);
    }
  }

  const connectAccount = async () => {
    select("TronLink");
    try {
      const tronLinkAdapter = new TronLinkAdapter();
      await connect(tronLinkAdapter);
      console.log("Connected address:", address);
    } catch (error) {
      if (error.message === "No wallet is selected. Please select a wallet.") {
        console.error("No wallet is selected. Please select a wallet.");
        // Handle the case where no wallet is selected
      } else {
        console.error("Error connecting TronLink:", error);
        // Handle other errors as needed
      }
    }
  };

  const connectAccount_ = () => {
    const tronWeb = window?.tronWeb;
    select("TronLink");
    if (tronWeb && !tronWeb.ready) {
      console.error("TronWeb not available");
      onError?.("Unlock TronLink");

      window.alert("Unlock TronLink");
      window.location.reload();
      // window.history.back()
      // onError?.("TronWeb not available, install TronLink");
    }
  };

  // useEffect(() => {
  //   if (disconnect) WalletDisconnect();
  // }, [disconnect]);

  if (!window?.tronWeb) {
    return (
      <div>
        <p style={style}>No TronLink installed on this browser!</p>
      </div>
    );
  }

  if (disable) {
    return (
      <div>
        <p style={style}>Switch to Mainnet</p>
      </div>
    );
  }

  return (
    <div>
      {!connected ? (
        <button
          style={style}
          onClick={() => (connected ? WalletDisconnect() : connectAccount())}
          className={className}
        >
          {connected ? "DISCONNECT WALLET" : "CONNECT TRON LINK"}
        </button>
      ) : (
        <button
          style={style}
          onClick={() => onSignTransaction()}
          className={className}
        >
          {text}
        </button>
      )}
    </div>
  );
};

Tron.defaultProps = {
  dev: true,
};

export default Tron;
