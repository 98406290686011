import BoardDetails from "../../components/Board/BoardDetails";
import { useTranslation } from "react-i18next";

const Board = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="page_head_top">{t("boards")}</div>
      <BoardDetails />
    </>
  );
};
export default Board;
