import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CurrencyConverter from "../../Currency/CurrencyConverter";
const TronWalletTiles = ({ tiles, currency, conversionFactor }) => {
  const { t } = useTranslation();
  console.log(tiles, "tronwallettiles");
  return (
    <div className="ewallet_top_cnt_sction">
      <div className="row">
        <div className="col-xl-3 col-md-12">
          <div className="ewallet_top_qc_balance_box">
            <div className="row align-items-center">
              <div className="ewallet_top_qc_balance_box_cnt">
                <div className="ewallet_top_qc_balance_box_cnt_ico">
                  <img src={"/images/ewallet-ico2.png"} alt="" />
                </div>
                <div className="ewallet_top_qc_balance_box_cnt_cnt">
                  <div className="ewallet_top_qc_balance_box_cnt_head">
                    {t("tronWalletBalance")}
                  </div>
                  <div className="box_amnt_dv">
                    <div className="ewallet_top_qc_balance_box_cnt_val ewallet_top_vl">
                      {currency?.symbolLeft}{" "}
                      {CurrencyConverter(
                        tiles?.data?.tronWalletBalance,
                        conversionFactor
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TronWalletTiles;
