import React, { useState } from "react";
import TableMain from "../Common/table/TableMain";
import EwalletTableFilter from "../Common/table/EwalletTableFilter";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../hooks/apiHook";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const TronWalletTable = (props) => {
  const { t } = useTranslation();
  const [selectStatement, setSelectedStatement] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const statement = ApiHook.GetTronWalletStatement(
    props.currentPage,
    itemsPerPage,
    selectStatement
  );
  const headers = [t("id"), t("amount"), t("date"), t("status")];

  return (
    <div className="ewallet_table_section">
      <div className="ewallet_table_section_cnt">
        <div className="ewallet_table_section_cnt_tab_head">
          <Link className={`ewallet_tab_btn active`}>{t("statement")}</Link>
        </div>
      </div>
      <div className="table-responsive min-hieght-table">
        <TableMain
          headers={headers}
          data={statement?.data?.data}
          startPage={1}
          currentPage={statement?.data?.currentPage}
          totalPages={statement?.data?.totalPages}
          setCurrentPage={props.setCurrentPage}
          type={"tronStatement"}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
    </div>
  );
};

export default TronWalletTable;
