import React, { useState } from "react";
import { Offcanvas, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ApiHook } from "../../hooks/apiHook";
import { useQueryClient } from "@tanstack/react-query";
import SubmitButton from "../Common/buttons/SubmitButton";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { reverseNumberDisplay } from "../../utils/currencyNumberDisplay";
import CurrencyInput from "react-currency-input-field";
import TronPay from "./TronPay";
import { DEV } from "../../config/config";


const AddFundToTronWallet = ({
  balance,
  show,
  handleClose,
  currency,
  trxAddress,
}) => {
  console.log("tronWallet", trxAddress?.data?.trxAddress);
  const { t } = useTranslation();
  const [transferData, setTransferData] = useState({
    amount: "",
  });

  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );

  const defaultCurrencyJson = useSelector(
    (state) => state?.user?.loginResponse?.defaultCurrency
  );

  const defaultCurrency = defaultCurrencyJson
    ? JSON.parse(defaultCurrencyJson)
    : null;
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState({
    amount: null,
  });

  const isFloat = (n) => {
    return Number(n) === n && n % 1 !== 0;
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    console.log(isFloat(value), "isFloatResult", typeof value);
    if (id == "amount" && isFloat(Number(value))) {
      console.log("inside float value");
      setErrorMessage((prev) => ({
        ...prev,
        [id]: "Whole numbers only, please. Decimals or fractions are not allowed.",
      }));
      return;
    }
    setTransferData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    setErrorMessage((prevData) => ({
      ...prevData,
      [id]: null,
    }));
    if (value === null || value === "") {
      setErrorMessage((prev) => ({
        ...prev,
        [id]: "*Required",
      }));
    }
  };
  const transferMutation = ApiHook.AddTronWalletFundTransfer();
  const isFormValid = () => {
    return transferData?.amount > 0;
  };

  const handleSubmit = async (payment) => {
    console.log({payment});
    if (!isFormValid()) {
      return;
    }
    try {
      let convertAmount;
      if (currency.id === defaultCurrency.id) {
        convertAmount = reverseNumberDisplay(
          CurrencyConverter(parseInt(transferData?.amount), conversionFactor, 0)
        );
      } else {
        convertAmount = reverseNumberDisplay(
          CurrencyConverter(parseInt(transferData?.amount), conversionFactor, 1)
        );
      }
      const postData = {
        amount: convertAmount,
        hash: payment,
      };
      await transferMutation.mutateAsync(postData, {
        onSuccess: (res) => {
          if (res.status === 200) {
            toast.success(t("amountLoadedToCashPoint"));
            setTransferData({
              amount: "",
            });
            queryClient.invalidateQueries({
              queryKey: ["tron-wallet-statement"],
            });
            queryClient.invalidateQueries({ queryKey: ["tron-wallet-tiles"] });
            // toast.success(t("success"));
            handleClose(false);
          } else {
            if (res?.data?.code === 1014) {
              setErrorMessage((prevErrors) => ({
                ...prevErrors,
                amount: t(res?.data?.description),
              }));
            } else if (res?.data?.code) {
              toast.error(res?.data?.description);
            } else {
              toast.error(res?.message);
            }
          }
        },
      });
    } catch (error) {
      // Handle general error or network issue
      //   console.error("Error submitting transfer:", error);
      toast.error(t("someErrorOccured"));
    }
  };

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement="end"
      id="addFundToTronWallet"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{t("addFundToTronWallet")}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {/* <Form> */}
        <Form.Group className="mb-3">
          <Form.Label>{t("amount")} *</Form.Label>
          <Form.Control
            id="amount"
            type="number"
            name="amount"
            placeholder={t("pleaseEnterAmount")}
            onChange={(e) => handleChange(e)}
            value={transferData.amount}
            required
            isInvalid={errorMessage?.amount !== null}
          />
          <Form.Control.Feedback type="invalid">
            {errorMessage.amount}
          </Form.Control.Feedback>
        </Form.Group>
        <TronPay
          amount={transferData.amount}
          text="Send XRT"
          onInitiated={(payment) => {
            console.log("onInitiated", payment);
            toast.info("Initiated");
          }}
          onSuccess={async (payment) => {
            console.log("Await for blockchain confirmation...");
            console.log("success", payment);
            handleSubmit(payment);
          }}
          onError={(error) => {
            toast.error(error);
          }}
          style={{
            backgroundColor: "#444",
            padding: 10,
            paddingRight: 40,
            paddingLeft: 40,
            color: "#fff",
            fontWeight: "800",
            borderRadius: 10,
          }}
          disconnect={false}
          loading={false}
          className="btn btn-primary submit_btn"
          dev={DEV}
          address={trxAddress?.data?.trxAddress}
        />
        {/* <SubmitButton
            isSubmitting={!isFormValid() || transferMutation.isLoading}
            click={handleSubmit}
            disabled={transferMutation.isLoading}
            text={transferMutation.isLoading ? "Submitting..." : "Submit"}
            className={"btn btn-primary submit_btn"}
          /> */}
        {/* </Form> */}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AddFundToTronWallet;
