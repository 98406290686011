import { useTranslation } from "react-i18next";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";

const AvatarDetails = ({ avatarDetails }) => {
  const { t } = useTranslation();
  const storeLink = ApiHook.CallAvatarLoginLink();
  return (
    <div className="col-md-5">
      <div className="joinings_viewBox">
        <div className="joinings_viewBox_head">
          <h5>{t("avatar")}</h5>
        </div>
        <div className="teammbrs_cnt">
          {avatarDetails.map((avatar) => {
            return (
              <div className="teammbrs_cnt_row" key={avatar.id}>
                <div className="teammbrs_cnt_img">
                  <img
                    src={"/images/user-profile.png"}
                    alt={avatar?.username}
                  />
                </div>
                <div className="teammbrs_cnt_name_dtl">
                  <div className="teammbrs_cnt_name">{avatar?.username}</div>
                </div>
                {avatar.board > 0 && (
                  <div className="teammbrs_cnt_name_dtl">
                    <div className="teammbrs_cnt_name">
                      {t("stage")} {avatar?.board}
                    </div>
                  </div>
                )}
                <div className="teammbrs_cnt_name_dtl">
                  <button
                    className="top_profile_upgrade_renew_btn_1"
                    style={{ maxWidth: "100%" }}
                    onClick={() => {
                      storeLink.mutate(
                        {
                          loginUserId: avatar.id,
                        },
                        {
                          onSuccess: (data) => {
                            console.log(data, "onsuccess");
                            if (data.status) {
                              localStorage.clear();
                              window.location.href = data.data.link ?? "";
                            } else {
                              toast.error(
                                t(data.message || "Some Error Occured")
                              );
                            }
                          },
                        }
                      );
                    }}
                    disabled={storeLink.isLoading}
                  >
                    {t("login")}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AvatarDetails;
