import { CashPointService } from "../../services/cashPoint";

export const cashPointTiles = async () => {
  try {
    const response = await CashPointService.callTiles();
    return response;
  } catch (error) {
    return error.message;
  }
};

export const cashPointStatement = async (page, itemsPerPage) => {
  try {
    const response = await CashPointService.callStatement(page, itemsPerPage);
    return response;
  } catch (error) {
    return error.message;
  }
};

export const cashPointTransferHistory = async (
  page,
  itemsPerPage,
  selectedCategory,
  startDate,
  endDate
) => {
  try {
    const response = await CashPointService.callTransferHistory(
      page,
      itemsPerPage,
      selectedCategory,
      startDate,
      endDate
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const cashPointPurchaseHistory = async (page, itemsPerPage) => {
  try {
    const response = await CashPointService.callPurchaseHistory(
      page,
      itemsPerPage
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const cashPointMyEarnings = async (
  page,
  itemsPerPage,
  selectedCategory,
  startDate,
  endDate
) => {
  try {
    const response = await CashPointService.callMyEarnings(
      page,
      itemsPerPage,
      selectedCategory,
      startDate,
      endDate
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const cashPointFundTransfer = async (data) => {
  try {
    const response = await CashPointService.callFundTransfer(data);
    return response;
  } catch (error) {
    return error.message;
  }
};

export const EwalletBalance = async () => {
  try {
    const response = await CashPointService.callEwalletBalance();
    return response;
  } catch (error) {
    console.log(error.message);
  }
};
