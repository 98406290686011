import { TronWalletService } from "../../services/tronwallet/tronwallet";

export const addFund = async (data) => {
  try {
    const response = await TronWalletService.addFundToTronWallet(data);
    return response;
  } catch (error) {
    return error.message;
  }
};

export const TronWalletStatement = async (page, itemsPerPage) => {
    try {
        const response = await TronWalletService.getStatement(page, itemsPerPage);
        return response
    } catch (error) {
        return error.message
    }
}

export const tronTiles = async () => {
    try {
        const response = await TronWalletService.getTiles();
        return response
    } catch (error) {
        return error.message
    }
}