import React from "react";
import { Link } from "react-router-dom";
import { ApiHook } from "../../hooks/apiHook";
import Loader from "react-js-loader";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CurrencyConverter from "../../Currency/CurrencyConverter";

function BoardPurchaseReport() {
  const { t } = useTranslation();
  const { isLoading, isError, data } = ApiHook.CallGetStepPurchaseDetails();
  console.log(data,"data")
  const userSelectedCurrency = useSelector(
    (state) => state.user?.selectedCurrency
  );
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );
  return (
    <div style={{ height: "80vh" }}>
      <div className="page_head_top">{t("membership_purchase_details")}</div>
      {!isLoading && data?.status == false ? (
        <div className="text-center">
          <img src="/images/no-data-image1.jpg" alt="" />
          <h6 className="p-2">{data?.data?.description}</h6>
        </div>
      ) : (
        <div>
          <div className="ewallet_top_btn_sec">
            <div className="row justify-content-between"></div>
          </div>
          <div className="ewallet_table_section">
            <div className="table-responsive min-hieght-table">
              <div className="table-container">
                <table className="striped">
                  <thead>
                    <tr>
                      <th>{t("id")}</th>
                      <th>{t("name")} </th>
                      <th>{t("stage")}</th>
                      <th>{t("amount")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.data?.map((item) => (
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{item.name}</td>
                        <td>{item.stage}</td>
                        <td>{`${
                          userSelectedCurrency.symbolLeft
                        } ${CurrencyConverter(
                          item.amount,
                          conversionFactor
                        )}`}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BoardPurchaseReport;
